<template>
    <a-modal title="直播禁用时间" width='50%' :visible="visible" :confirmLoading="confirmLoading" :destroyOnClose="true"
        @cancel="handleCancel" @ok="handleSubmit">
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form :form="form" layout="inline">
                    <a-form-item label="截止时间">
                        <a-date-picker placeholder="请选择截止时间" @change="onChange" style="width: 100%" show-time
                            v-decorator="['endTime', { rules: [{ required: false, message: '请选择直播禁用截止时间' }] }]" />
                    </a-form-item>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { liveAdminEnd } from '@/api/modular/mallLiving/liveRoomAdmin'

export default {
    data() {
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            id: '',
            details: {},
            form: this.$form.createForm(this),
            showBigImg: false,
            liveId: ''
        }
    },
    methods: {
        //初始化方法
        detail(record) {
            this.visible = true
            this.confirmLoading = false
            this.liveId = record.id
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel() {
            this.visible = false
            this.confirmLoading = false
            this.details = {}
        },
        showImg() {
            this.showBigImg = true
        },
        handleCancel_1() {
            this.showBigImg = false
        },
        onChange(date) {
            var date = new Date(date).getTime()
            return date
        },

        handleSubmit() {
            const { form: { validateFields } } = this
            this.confirmLoading = true
            validateFields((errors, values) => {
                if (!errors) {
                    console.log(values)
                    if (values.endTime) {
                        let time = this.onChange(values.endTime._d)
                        console.log(new Date().getTime() - time)
                        if (new Date().getTime() > time) {
                            this.$message.error("截止时间必须晚于当前时间")
                            this.confirmLoading = false
                            return
                        } else {
                            liveAdminEnd({ id: this.liveId, time: time }).then(res => {
                                if (res.success) {
                                    this.$message.success("操作成功！")
                                    this.$emit("ok", values)
                                    this.confirmLoading = false
                                    this.handleCancel()
                                } else {
                                    setTimeout(() => {
                                        this.confirmLoading = false
                                    }, 600)
                                }
                            })
                        }
                    } else {
                        liveAdminEnd({ id: this.liveId }).then(res => {
                            if (res.success) {
                                this.$message.success("操作成功！")
                                this.$emit("ok", values)
                                this.confirmLoading = false
                                this.handleCancel()
                            } else {
                                setTimeout(() => {
                                    this.confirmLoading = false
                                }, 600)
                            }
                        })
                    }

                } else {
                    this.confirmLoading = false
                    if (res.success) {
                        this.$message.success("操作成功！")
                        this.$emit("ok", values)
                        this.confirmLoading = false
                        this.handleCancel()
                    } else {
                        setTimeout(() => {
                            this.confirmLoading = false
                        }, 600)
                    }
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.mytable {
    margin-bottom: 70px;
    border-collapse: collapse;
    width: 100%;
    height: 250px;

    .title {
        background: rgb(207, 248, 248);
        width: 20%
    }

    .con {
        width: 30%
    }

    td {
        border: 2px solid rgb(228, 225, 225);
        padding-left: 7px;
        font-size: 15px;
    }
}

img {
    width: 100px;
    height: 50px;
}
</style>